import React from "react";
import { MotionConfig, motion } from "motion/react";

import styles from "../../sass/components/Hamburger.module.scss";


type HamburgerProps = {
    active: boolean;
    setActive: React.Dispatch<React.SetStateAction<boolean>>;
}



export default function Hamburger(props: HamburgerProps) {
    return (
        <MotionConfig
            transition={{
                duration: 0.5,
                ease: "easeInOut",
            }}
        >
            <motion.button 
                initial={false}
                onClick={() => {
                    props.setActive((pv: boolean) => !pv)}
                }
                className={styles.hamburger}
                animate={props.active ? "open" : "closed"}
            >
                <motion.span 
                    style={{
                        left: "50%", 
                        top: "35%", 
                        x: "-50%", 
                        y: "-50%",
                    }}
                    variants={{
                        open: {
                            rotate: ["0deg", "0deg", "45deg"],
                            top: ["35%", "50%", "50%"],
                        },
                        closed: {
                            rotate: ["45deg", "0deg", "0deg" ],
                            top: ["50%", "50%", "35%"],
                        }
                    }}
                ></motion.span>
                <motion.span 
                    style={{
                        left: "50%", 
                        top: "50%", 
                        x: "-50%", 
                        y: "-50%",
                    }}
                    variants={{
                        open: {
                            rotate: ["0deg", "0deg", "-45deg" ],
                        },
                        closed: {
                            rotate: ["-45deg", "0deg", "0deg" ],
                        }
                    }}
                ></motion.span>
                <motion.span 
                    style={{
                        left: "50%", 
                        bottom: "35%", 
                        x: "-50%", 
                        y: "50%",
                    }}
                    variants={{
                        open: {
                            rotate: ["0deg", "0deg", "45deg"],
                            left: "50%",
                            bottom: ["35%", "50%", "50%"]
                        },
                        closed: {
                            rotate: ["45deg", "0deg", "0deg"],
                            left: "50%",
                            bottom: ["50%", "50%", "35%"]
                        }
                    }}
                ></motion.span>
            </motion.button>
        </MotionConfig> 
    )
}