import React, { Dispatch, SetStateAction } from "react";

import styles from "../../sass/components/Slider.module.scss";

type DotsProps = {
    items: Array<string>
    imgIndex: number;
    setImgIndex: Dispatch<SetStateAction<number>>
}

export default function Dots(props: DotsProps){
    
    return (
        <div className={styles.dot_container}>
            { props.items.map((_, index: number) => (
                <button key={index} onClick={() => props.setImgIndex(index)} className={`
                    ${styles.dot}
                    ${index === props.imgIndex ? styles.active : styles.inActive} 
                `}/>
            ))}
        </div>
    )
}