import React from 'react';
import * as ReactDOM from "react-dom/client";

import { RouterProvider } from "react-router-dom";

import { route } from './routes/routes';

import "./sass/main.scss"


ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<RouterProvider router={route} />
	</React.StrictMode>
);
