import { createBrowserRouter } from "react-router-dom";
import Root from "./root";

import ServiceScreen from "../Screens/ServiceScreen";
import HomeScreen from "../Screens/HomeScreen";


export const route = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		children: [
			{
				path: "",
				element: <HomeScreen />
			},
			{
				path: "/services",
				element: <ServiceScreen />
			}
		]
	}
]);