import styles from "../sass/components/ServiceCard.module.scss";

type ServiceCardProps = {
    data: any
}

export default function ServiceCard(props: ServiceCardProps) {

    return (
        <div className={styles.serviceCard}>
            <div className={styles.overlap}>
                <div className={styles.img_container}>
                    <img src={props.data.img} alt=""/>
                </div>
                <div className={styles.info_container}>
                    <h4>{props.data.title}</h4>
                    <p>{props.data.description}</p>
                </div>
            </div>
        </div>
    )
}