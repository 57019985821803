import React, { useEffect, useState } from "react";

import styles from "../sass/components/Header.module.scss";

import Hamburger from "./Header/Hamburger";
import MobileMenu from "./Header/MobileMenu";
import DesktopMenu from "./Header/DesktopMenu";
import Block from "./Block";
import { useLocation } from "react-router-dom";

export default function Header() {
    const [menuState, setMenuState] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(window.innerWidth);
 
    useEffect(() => {
        window.addEventListener("resize", handleReszie)

        return () => { window.removeEventListener("resize", handleReszie) }
    }, []);

    function handleReszie() {
        setWidth(window.innerWidth);
    }

    let isMobile: boolean = width <= 800 ? true : false;


    return (
        <header className={styles.header}>
            <Block>
                <div className={styles.overlap}>
                    <div className={styles.img_container}>
                        <a href="/"><img src="/img/ekholmsrekond-logo.png" alt="Logo" /></a>
                    </div>
                    { isMobile ? (
                        <Hamburger active={menuState} setActive={setMenuState} />
                    ) : ( 
                        <DesktopMenu />
                    )}
                </div>
            </Block>

            { isMobile && (
                <MobileMenu active={menuState} setActive={setMenuState}/>
            )}
            
        </header>   
    )   
}




