export const services = [
    {
        "title": "Bedömning/felsökning av fordon",
        "description": "",
        "time": "30 minuter",
        "price": 500,
        "link": "",
    },
    {
        "title": "Olje service Personbil",
        "description": "",
        "time": "2 timmar",
        "price": undefined,
        "link": "",
    },
    {
        "title": "Olje service Husbil",
        "description": "",
        "time": "2 timmar",
        "price": undefined,
        "link": "",
    },
    {
        "title": "Bromsbyte",
        "description": "",
        "time": "1 timmar",
        "price": undefined,
        "link": "",
    },
    {
        "title": "Utvändig rekond",
        "description": "",
        "time": "4 timmar",
        "price": 2990,
        "link": "",
    },
    {
        "title": "Keramiskt lackskydd",
        "description": "",
        "time": "8 timmar",
        "price": 5990,
        "link": "",
    },
    {
        "title": "Dammsugning",
        "description": "",
        "time": "30 Minuter",
        "price": 300,
        "link": "",
    },
    {
        "title": "Invändig rekond ",
        "description": "",
        "time": "4 timmar",
        "price": 1290,
        "link": "",
    },
    {
        "title": "Dragkroksmontering inkl. drag",
        "description": "",
        "time": "6 timmar",
        "price": 5575,
        "link": "",
    },
    {
        "title": "Rekond In & Utvändig",
        "description": "",
        "time": "8 timmar",
        "price": 3790,
        "link": "",
    },
    {
        "title": "Våtdammsugning",
        "description": "",
        "time": "3 timmar",
        "price": 1000,
        "link": "",
    },
    {
        "title": "Motortvätt",
        "description": "",
        "time": "30 Minuter",
        "price": 300,
        "link": "",
    },
    {
        "title": "Ozonbehandling",
        "description": "",
        "time": "4 Timmar",
        "price": 1000,
        "link": "",
    },
    {
        "title": "Solfilm",
        "description": "",
        "time": "1 Dag",
        "price": 300,
        "link": "",
    },
    {
        "title": "Fukttest Husvagn /Husbil",
        "description": "",
        "time": "4 timmar",
        "price": 900,
        "link": "",
    },
]
