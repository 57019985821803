import React from "react"

import Block from "../components/Block";
import Service from "../components/Service";

import styles from "../sass/screens/ServiceScreen.module.scss"

import { services } from "../db";

export default function ServiceScreen() {

	return (
		<Block>
			<div className={styles.serviceScreen}>
				{ services.map((service, index) => (
					<Service key={index} data={service}/>
				)) }
			</div>
		</Block>
	)
}
