import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";

import styles from "../sass/components/Slider.module.scss";
import { motion, useMotionValue } from "motion/react";

import Dots from "./Slider/Dots";
import Images from "./Slider/Images";

const items: Array<string> = [
    "./img/car1.png",
    "./img/car2.png",
    "./img/car3.png",
]

const AUTO_DELAY = 1000 * 8;

export default function Slider() {
    const [imgIndex, setImgIndex] = useState<number>(0)
    
    const dragX = useMotionValue<number>(0);

    useEffect(() => {
        const intervalRef = setInterval(() => {
            const x = dragX.get();  

            if (x === 0) {
                setImgIndex((pv) => {

                    if (pv === items.length - 1) {

                        return 0;
                    }

                    return pv + 1;
                });
            }
        }, AUTO_DELAY);

        return () => clearInterval(intervalRef);
    }, []);

    const onDragEnd = () => {
        const x = dragX.get();

        if (x <= -10 && imgIndex < items.length - 1) {
            setImgIndex((pv) => pv + 1);

        } else if (x >= 10 && imgIndex > 0) {
            setImgIndex((pv) => pv - 1);

        }
    };


    return (
        <div className={styles.slider}>
            <motion.div 
                className={styles.track}
                style={{
                    x: dragX
                }}

                drag="x"
                dragConstraints={{left: 0, right: 0}}
                transition={{
                    type: "spring",
                    bounce: 0,
                    duration: 0.5
                }}
                animate={{
                    translateX: `-${imgIndex * 100}%`,
                }}

                onDragEnd={onDragEnd}
            >
                <Images items={items} imgIndex={imgIndex}/>
            </motion.div>
            <Dots items={items} imgIndex={imgIndex} setImgIndex={setImgIndex} />
        </div>
    )
}




