import React from "react"
import styles from "../sass/components/Block.module.scss";

interface BlockProps {
	children: React.ReactNode;
	fullbleed?: Boolean;
}


export default class Block extends React.Component<BlockProps> {	
	static defaultProps: BlockProps = {
		fullbleed: false,
		children: null,
	}

	render() {
		const { fullbleed, children } = this.props;
		const classes = [styles.block, fullbleed && styles.fullbleed].filter(Boolean).join(" ");

		return (
			<div className={ classes }>
				{ children }
			</div>
		);
	}
}
