import React from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";

import styles from "../../sass/components/Menu.module.scss";

type NavLinkProps = {
    to: string;
    children: React.ReactNode
}

export default function MobileNavLink(props: NavLinkProps ) {
    const location = useLocation();
    const uri: string = location.pathname;

    const variants = {
        closed: {
            y: "100%",
            opacity: 0,
        },
        open: {
            y: "0",
            opacity: 1,
            transition: {
                duration: 0.7,
            },
        },
    };

    return (
        <motion.li variants={variants}>
            <Link 
                to={props.to}
                className={`${uri === props.to && styles.active }`}
            >
                {props.children}    
            </Link>
        </motion.li>
    )
}