import { motion } from "motion/react";

type ImageProps = {
    items: Array<string>
    imgIndex: number; 
}

export default function Images(props: ImageProps) {

    return <>
        { props.items.map((item, index: number) => (
            <motion.img
                key={index} 
                src={item}
                draggable={false}

                animate={{
                    scale: props.imgIndex === index ? 1 : 0.90,
                    transition: {
                        duration: 0.3
                    }
                }}
            ></motion.img>
        ))}
    </>
}